import {useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {For, If} from 'babel-plugin-jsx-control-statements'
import {media} from '../../../utils'
import theme from '../../../theme/theme'
import Image from '../../atoms/Image'


const StyledSlider = styled.div`
  display: flex;
  flex-direction: column;
  grid-row: 1 / 6;
  align-items: center;
  text-align: center;
  color: grey;
  font-size: 1rem;

  ${media.down('mobile')(css`
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    order: 2;
  `)}
`

// Does not include defaultValidatorFn because it does not accepts image prop
const StyledImg = styled(Image).withConfig({shouldForwardProp: (prop) => (
  !['isClickable'].includes(prop)
)})`
  display: block;
  cursor: ${({isClickable}) => isClickable ? 'pointer' : 'default'};
  max-width: 32rem;
  max-height: 32rem;

  ${media.down('mobile')(css`
    margin-top: 1.5rem;
    max-width: 100%;
  `)}
`

const StyledBullet = styled.span`
  display: inline-block;
  margin: 0 0.5rem;
  border: 1px solid ${theme.colors.gray89};
  border-radius: 50%;
  cursor: pointer;
  width: 0.625rem;
  height: 0.625rem;

  ${({isActive}) => isActive && css`
    background-color: ${theme.colors.gray89};
  `}

  ${media.down('mobile')(css`
    width: 0.75rem;
    height: 0.75rem;
  `)}
`

const ReferenceSlider = ({sliderImages, ...props}) => {
  const [currentIndex, setIndex] = useState(0)

  const handleClick = (index) => {
    if (sliderImages.length > index) {
      return setIndex(index)
    }

    return setIndex(0)
  }

  return (
    <StyledSlider {...props}>
      <StyledImg
          isClickable={sliderImages.length > 1}
          onClick={() => handleClick(currentIndex + 1)}
          image={sliderImages[currentIndex].image}
          alt={sliderImages[currentIndex].alt}
          loading="eager"
      />
      <If condition={sliderImages.length > 1}>
        <div>
          <For each="sliderImage" of={sliderImages} index="idx">
            <StyledBullet key={idx} isActive={currentIndex === idx} onClick={() => handleClick(idx)} />
          </For>
        </div>
      </If>
    </StyledSlider>
  )
}

ReferenceSlider.propTypes = {
  sliderImages: PropTypes.array.isRequired,
}

export default ReferenceSlider
