import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {mapValues} from 'lodash-es'
import DetailHero from '../components/molecules/ReferenceDetail/DetailHero'
import ReferenceContent from '../components/molecules/ReferenceDetail/ReferenceContent'
import SectionCallToAction from '../components/molecules/SectionCallToAction'
import SectionDownload from '../components/molecules/ReferenceDetail/SectionDownload'
import Navbar from '../components/containers/Navbar'
import AppShell from '../components/containers/AppShell'
import useLang from '../hooks/useLang'


const Reference = ({data, pageContext: {lang: pageLang}}) => {
  const langToSlug = mapValues(data?.markdownRemark.frontmatter, 'slug')
  const {lang} = useLang(pageLang, langToSlug)
  const pageData = data.markdownRemark.frontmatter[lang]

  return (
    <AppShell title={pageData.hero.title} lang={lang} langToSlug={langToSlug} seo={pageData.seo}>
      <Navbar
          title={pageData.returnButton.text}
          titleLink={pageData.returnButton.link}
          lang={lang}
          langToSlug={langToSlug}
      />
      <DetailHero
          lang={lang}
          title={pageData.hero.title}
          providers={pageData.hero.providers}
          subTitle={pageData.hero.subtitle}
          description={pageData.hero.description}
          keyServices={pageData.hero.keyServices}
          sliderImages={pageData.hero.featuredImages}
      />
      <main>
        <ReferenceContent content={pageData.content} />
        <SectionDownload documents={pageData.documents} />
        <SectionCallToAction
            bottom
            mobileCTAVisible
            link={pageData.callToAction.action.link}
            linkText={pageData.callToAction.action.text}
        >
          {pageData.callToAction.title}
        </SectionCallToAction>
      </main>
    </AppShell>
  )
}

export const data = graphql`
  query Reference($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...ReferencePageFragment
      }
    }
  }
`

Reference.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }).isRequired,
}

export default Reference
