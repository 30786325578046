import {AppStoreIcon, WwwBasicIcon} from './assets/icons'
import GooglePlayIcon from './assets/logos/google-play.png'


export const storeIcons = {
  appStore: {
    icon: <AppStoreIcon />,
    label: 'App store',
  },
  web: {
    icon: <WwwBasicIcon />,
    label: 'WWW',
  },
  googlePlay: {
    icon: <img src={GooglePlayIcon} alt="Google Play" loading="lazy" />,
    label: 'Google Play',
  },
}
