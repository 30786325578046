import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Choose, When, Otherwise, For, If} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash-es'
import {media, convertMDContent} from '../../../utils'
import Typography from '../../atoms/Typography'
import Image from '../../atoms/Image'


const StyledContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 4.625rem;

  ${media.down('mobile')(css`
    margin: 0;
  `)}
`

const StyledContentWrapper = styled.div`
  display: flex;
  order: ${({position}) => position === 'right' ? '2' : '1'};
  padding: 1.5rem;
  width: ${({fullWidth}) => fullWidth ? '100%' : '50%'};

  ${media.down('mobile')(css`
    padding: 1.5rem 1.5rem 0;
    width: 100%;
  `)}
`

const StyledTextContainer = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 5rem;
  width: 100%;
  text-align: center;

  p {
    margin-bottom: 2rem;
    text-align: justify;
  }

  h3 {
    margin-bottom: 2rem;
    text-align: left;
  }

  h4 {
    margin-bottom: 2rem;
  }

  li {
    text-align: left;
  }

  ${media.down('mobile')(css`
    padding: 4rem 0;
  `)}
`

const StyledImage = styled(Image)`
  border-radius: 0.35rem;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`

const ReferenceContent = ({content, ...props}) => {
  return (
    <For each="item" of={content} index="idx">
      <StyledContentContainer {...props} key={idx}>
        <If condition={!isEmpty(item.text)}>
          <StyledContentWrapper position={item.textPosition}>
            <StyledTextContainer
                type="div"
                dangerouslySetInnerHTML={{__html: convertMDContent(item.text)}}
            />
          </StyledContentWrapper>
        </If>
        <If condition={!isEmpty(item.images)}>
          <Choose>
            <When condition={item.images.length === 1 && isEmpty(item.text)}>
              <StyledContentWrapper fullWidth>
                <StyledImage image={item.images[0].image} alt={item.images[0].alt} />
              </StyledContentWrapper>
            </When>
            <Otherwise>
              <For each="image" of={item.images} index="idx">
                <If condition={!isEmpty(image.image)}>
                  <StyledContentWrapper key={idx}>
                    <StyledImage image={image.image} alt={image.alt} />
                  </StyledContentWrapper>
                </If>
              </For>
            </Otherwise>
          </Choose>
        </If>
      </StyledContentContainer>
    </For>
  )
}

ReferenceContent.propTypes = {
  content: PropTypes.array.isRequired,
}

export default ReferenceContent
